* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: black;
}

body {
  font-family: sans-serif;
  font-size: min(12vw, 86px);
  line-height: 0.75;
}

h1 {
  margin: 0;
}

/*canvas + div {*/
/*  mix-blend-mode: multiply;*/
/*}*/

.htmlScreen iframe
{
  width: 1100px;
  height: 775px;
  border: none;
  border-radius: 20px;
}

